import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionResponse } from '../../types/DataResult';
import { RESTServerRoute } from '../../types/Server';
import { Tag } from '../../types/Tag';
import { buildUrl } from '../../utils/url/url.util';

@Injectable({
  providedIn: 'root',
})
export class TagApiService {
  public constructor(private httpClient: HttpClient) {}

  public getTag(tagID: string): Observable<Tag> {
    const params = { WithUser: 'true' };

    const url = buildUrl(RESTServerRoute.REST_TAG, { id: tagID });
    return this.httpClient.get<Tag>(url, {
      params,
    });
  }

  public getTagByVisualID(tagVisualID: string): Observable<Tag> {
    const params: { [param: string]: string } = { VisualID: tagVisualID, WithUser: 'true' };

    return this.httpClient.get<Tag>(RESTServerRoute.REST_TAGS, {
      params,
    });
  }

  public getTagByCarID(carID: string): Observable<Tag> {
    const params: { [param: string]: string } = { CarID: carID, WithUser: 'true' };

    return this.httpClient.get<Tag>(RESTServerRoute.REST_TAGS, {
      params,
    });
  }

  public createTag(tag: Tag): Observable<ActionResponse> {
    return this.httpClient.post<ActionResponse>(RESTServerRoute.REST_TAGS, tag, {});
  }

  public updateTag(tag: Tag): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_TAG, { id: tag.visualID });

    return this.httpClient.put<ActionResponse>(url, tag);
  }

  public activateRoaming(tagIDs: string[]): Observable<ActionResponse> {
    return this.httpClient.post<ActionResponse>(RESTServerRoute.REST_TAGS_ACTIVATE_ROAMING, { tagIDs });
  }
}
