import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StatusCodes } from 'http-status-codes';
import { TagApiService } from '../../api/tag/tag.api.service';
import { Tag } from '../../types/Tag';
import { ActionResponse } from '../../types/DataResult';
import { RestResponse } from '../../types/GlobalType';
import { HTTPError } from '../../types/HTTPError';
import { TagNotFoundError } from './errors/tag-not-found.error';
import { TagNotUpdatedError } from './errors/tag-not-updated.error';
import { TagVisualIdAlreadyExistsError } from './errors/tag-visual-id-already-exists.error';
import { TagAlreadyExistsError } from './errors/tag-already-exists.error';
import { TagNotCreatedError } from './errors/tag-not-created.error';

@Injectable()
export class TagRepositoryService {
  public constructor(private tagApi: TagApiService) {}

  public getTag(tagID: string): Observable<Tag> {
    return this.tagApi.getTag(tagID).pipe(
      catchError((error) => {
        if (error.status === StatusCodes.NOT_FOUND) {
          throw new TagNotFoundError();
        }

        throw error;
      }),
    );
  }

  public getTagByVisualID(tagVisualID: string): Observable<Tag> {
    return this.tagApi.getTagByVisualID(tagVisualID).pipe(
      catchError((error) => {
        if (error.status === StatusCodes.NOT_FOUND) {
          throw new TagNotFoundError();
        }

        throw error;
      }),
    );
  }

  public getTagByCarID(carID: string): Observable<Tag> {
    return this.tagApi.getTagByCarID(carID).pipe(
      catchError((error) => {
        if (error.status === StatusCodes.NOT_FOUND) {
          throw new TagNotFoundError();
        }

        throw error;
      }),
    );
  }

  public createTag(tag: Tag): Observable<ActionResponse> {
    return this.tagApi.createTag(tag).pipe(
      catchError((error) => {
        if (error.status === HTTPError.TAG_VISUAL_ID_ALREADY_EXIST_ERROR) {
          throw new TagVisualIdAlreadyExistsError(tag.visualID);
        }

        if (error.status === HTTPError.TAG_ALREADY_EXIST_ERROR) {
          throw new TagAlreadyExistsError(tag.id);
        }

        throw new TagNotCreatedError();
      }),
      map((response: ActionResponse) => {
        if (response.status !== RestResponse.SUCCESS) {
          throw new TagNotCreatedError();
        }

        return response;
      }),
    );
  }

  public updateTag(tag: Tag): Observable<ActionResponse> {
    return this.tagApi.updateTag(tag).pipe(
      catchError((error) => {
        if (error.status === HTTPError.TAG_VISUAL_ID_ALREADY_EXIST_ERROR) {
          throw new TagVisualIdAlreadyExistsError(tag.visualID);
        }

        throw new TagNotUpdatedError();
      }),
      map((response: ActionResponse) => {
        if (response.status !== RestResponse.SUCCESS) {
          throw new TagNotUpdatedError();
        }

        return response;
      }),
    );
  }
}
